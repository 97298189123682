<!--运营报修确认-->
<template>
  <div>
    <component :is="componentName"></component>
  </div>
</template>

<script>
import operationRepairConfirmDetail from "./detail";
import operationRepairConfirmList from "./list";
export default {
  name: "operation-repair-confirm",
  components:{
    operationRepairConfirmList,
    operationRepairConfirmDetail
  },
  watch:{
    '$route.path':function (newval) {
      if(newval.includes('/detail')){
        this.componentName = 'operationRepairConfirmDetail'
        return;
      }
      if(newval.includes('/checkModule/property-service/operation-repair-confirm')){
        this.componentName = 'operationRepairConfirmList'

      }
    }
  },
  data(){
    return {
      componentName:'operationRepairConfirmList'
    }
  }
}
</script>

<style scoped>

</style>